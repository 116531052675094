import React, { Fragment } from 'react'
import Alert from 'react-bootstrap/Alert'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form'
import Table from 'react-bootstrap/Table'
import QuantitativeAnalysis from './QuantitativeAnalysis'

function TestResultsModal({
  users,
  testResults,
  showTestResults,
  setShowTestResults,
  constructedQuery,
  soqlQuery,
  startDate,
  endDate,
  selectedUsersCrmIds,
}) {
  const handleClose = () => {
    setShowTestResults(false)
  }
  const renderQueryBox = () => {
    return (
      <Form>
        <Form.Group controlId='query'>
          {!!soqlQuery && (
            <Form.Group controlId='queryAsSoql'>
              <Form.Label>
                This is the query that we ran in Salesforce to get these performances, you can run
                it in your Developer Console any time you want to check the numbers directly for
                these users for this metric for this time period.
              </Form.Label>
              <Form.Control
                as='textarea'
                disabled
                rows='3'
                value={soqlQuery.replace(/%25/g, '%').replace(/%26/g, '&')}
              />
            </Form.Group>
          )}
          <Form.Label>
            If these numbers match your expectations, copy the Query Information below and give it
            to the DataBased Team Members so they can make it official.
          </Form.Label>
          <Form.Control as='textarea' disabled rows='3' value={JSON.stringify(constructedQuery)} />
        </Form.Group>
      </Form>
    )
  }

  const resultsContainError = () => {
    return testResults && testResults.length > 0 && testResults[0]['errorCode']
  }

  const renderTestResults = () => {
    const { summationField } = constructedQuery

    const operation = !summationField ? 'Count ' : `Summation of ${summationField} field `
    const repsToTest = selectedUsersCrmIds

    if (resultsContainError()) {
      return (
        <Alert variant='danger'>
          <h4>The query responded with:</h4>
          <p>Error Code: {testResults[0]['errorCode']}</p>
          <p>Message: {testResults[0]['message']}</p>
        </Alert>
      )
    }
    if (repsToTest && repsToTest.length > 0 && testResults) {
      const testResultValues = testResults.map((result) => result.performance)
      for (let i = 0; i < repsToTest.length - testResults.length; i++) {
        testResultValues.push(0)
      }
      repsToTest.sort((a, b) => {
        const nameA = getNameByCrmId(a)
        const nameB = getNameByCrmId(b)
        if (nameA > nameB) {
          return 1
        } else if (nameB > nameA) {
          return -1
        }
        return 0
      })
      return (
        <Fragment>
          <QuantitativeAnalysis values={testResultValues} />
          <Table bordered size='sm'>
            <thead>
              <tr>
                <th>Rep Name</th>
                <th>{operation}</th>
              </tr>
            </thead>
            <tbody>
              {repsToTest.map((repCrmId) => {
                const resultIndex = testResults.findIndex((result) => result.userId === repCrmId)
                const result = resultIndex === -1 ? 0 : testResults[resultIndex].performance
                return (
                  <tr key={repCrmId}>
                    <td>{getNameByCrmId(repCrmId)}</td>
                    <td>{result}</td>
                  </tr>
                )
              })}
            </tbody>
          </Table>
        </Fragment>
      )
    }
    return <div>Something went wrong with the query... Sorry I can't be of more help.</div>
  }

  const getNameByCrmId = (crmId) => {
    const indexOfUser = users.findIndex((user) => user.crmId === crmId)
    return users[indexOfUser].fullName
  }

  return (
    <Modal show={showTestResults} onHide={handleClose} size='lg'>
      <Modal.Header closeButton>
        <Modal.Title>Results of Query Test</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {renderTestResults()}
        {!resultsContainError() && renderQueryBox()}
      </Modal.Body>
      <Modal.Footer>
        <Button id='close-results-modal' variant='primary' onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default TestResultsModal
