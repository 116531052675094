import React, { Fragment, useState } from 'react'
import Button from 'react-bootstrap/Button'
import Card from 'react-bootstrap/Card'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import DateHelper from '../../Utils/DateHelper'
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import SyncMicroServiceAPI from './SyncMicroServiceAPI'
import UserList from '../UserList'
import { USER_ID_SEARCH_AND_REPLACE_KEY } from '../QueryBuilder'

function QueryTester({
  isDisabled,
  users,
  setIsLoading,
  notifyUser,
  setTestResults,
  setShowTestResults,
  setSOQLQuery,
  constructedQuery,
  setSelectedUserCrmIds,
  selectedUsersCrmIds,
  authToken,
}) {
  const [startDate, setStartDate] = useState(
    DateHelper.getYyyyMmDdString(DateHelper.previousMonth())
  )
  const [endDate, setEndDate] = useState(DateHelper.getYyyyMmDdString(new Date()))

  const render = () => {
    return (
      <Fragment>
        <Card className='wrapper'>
          <Card.Body>
            <Card.Title>Test This Query in Salesforce</Card.Title>
            <UserList users={users} setSelectedUserCrmIds={setSelectedUserCrmIds} />
            {renderSelectDaysBack()}
            <Button
              disabled={isDisabled ? '' : 'disabled'}
              id='run-test-button'
              onClick={runTestOnQuery}
              variant='primary'
            >
              Get Sample Data From Salesforce with this Query
            </Button>
          </Card.Body>
        </Card>
      </Fragment>
    )
  }

  const renderSelectDaysBack = () => {
    const noDateFieldSelected = !constructedQuery || !constructedQuery.dateField

    return (
      <Container>
        <Row>
          <Form>
            {!noDateFieldSelected && (
              <Fragment>
                <Col>
                  <Form.Group controlId='start-date'>
                    <Form.Label>Start Date of sample data range (Inclusive)</Form.Label>
                    <Form.Control
                      type='date'
                      value={startDate}
                      onChange={setNewDate}
                      max={DateHelper.getYyyyMmDdString()}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group controlId='end-date'>
                    <Form.Label>End Date of sample data range (Inclusive)</Form.Label>
                    <Form.Control
                      type='date'
                      value={endDate}
                      onChange={setNewDate}
                      max={DateHelper.getYyyyMmDdString()}
                    />
                  </Form.Group>
                </Col>
              </Fragment>
            )}
          </Form>
        </Row>
      </Container>
    )
  }

  const setNewDate = (event) => {
    if (event.target.id === 'start-date') {
      setStartDate(event.target.value)
    } else if (event.target.id === 'end-date') {
      setEndDate(event.target.value)
    }
  }

  const testIsLimitedToOneUser = () => {
    return (
      constructedQuery.groupingColumn ||
      constructedQuery.filterRule ||
      !constructedQuery.userIdField ||
      (constructedQuery.whereClause &&
        constructedQuery.whereClause.includes(USER_ID_SEARCH_AND_REPLACE_KEY))
    )
  }

  const runTestOnQuery = async () => {
    setSOQLQuery(null)
    if (selectedUsersCrmIds.length === 0) {
      notifyUser('Please select some users to test')
      return
    }
    if (selectedUsersCrmIds.length > 1 && testIsLimitedToOneUser()) {
      notifyUser('Please select exactly ONE user to test for the query you have built.')
      return
    }

    setIsLoading(true)
    const tester = new SyncMicroServiceAPI(
      setSOQLQuery,
      constructedQuery,
      selectedUsersCrmIds,
      startDate,
      endDate,
      authToken
    )
    const res = await tester.getResults()
    setTestResults(res)
    setIsLoading(false)
    setShowTestResults(true)
  }

  return render()
}

export default QueryTester
