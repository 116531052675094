import React, { Fragment } from 'react'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Card from 'react-bootstrap/Card'

function Login({ baseUrl, setAuthToken, setIsLoading, setIsLoggedIn, notifyUser }) {
  const handleLogin = async (event) => {
    setIsLoading(true)
    event.preventDefault()
    const email = document.getElementById('email').value
    const password = document.getElementById('password').value
    const data = { email, password }
    if (email && password) {
      const loginResponse = await fetch(`${baseUrl}/api/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      })
      const loginData = await loginResponse.json()
      const loggedInSuccessfully = loginData && loginData.auth
      if (loggedInSuccessfully) {
        const isAdmin =
          loginData.auth.accountType === 'db-admin' || loginData.auth.accountType === 'admin'
        if (isAdmin) {
          setAuthToken(loginData.auth.token)
          setIsLoggedIn(true)
        } else {
          notifyUser(
            'You must be an Admin in the DataBased system to login. Please contact your administrator for assistance.'
          )
        }
      } else {
        notifyUser('Login attempt was unsuccessful. Please check your username and password.')
      }
      setIsLoading(false)
    }
  }
  return (
    <Fragment>
      <Card className='wrapper' id='login-card'>
        <Card.Body>
          <Card.Title>Salesforce Query Tester</Card.Title>
          <Card.Text>
            For administrators to experiment with SOQL queries used to integrate Salesforce and
            DataBased (SOQL expertise not required).
          </Card.Text>
          <Form id='login-form' onSubmit={handleLogin}>
            <Form.Group controlId='email'>
              <Form.Label>Email</Form.Label>
              <Form.Control type='text' placeholder='michael@dundermifflin.com' required />
            </Form.Group>
            <Form.Group controlId='password'>
              <Form.Label>Password</Form.Label>
              <Form.Control type='password' />
            </Form.Group>
            <Button variant='primary' type='submit' required>
              Login
            </Button>
          </Form>
        </Card.Body>
      </Card>
    </Fragment>
  )
}

export default Login
