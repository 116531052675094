class TestResult {
  constructor(userId, performance) {
    this.p = performance
    this.u = userId
  }
  get performance() {
    return this.p
  }
  get userId() {
    return this.u
  }
}
export default TestResult
