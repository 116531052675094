import React, { Fragment, useEffect, useState } from 'react'
import Button from 'react-bootstrap/Button'
import Card from 'react-bootstrap/Card'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Modal from 'react-bootstrap/Modal'
import Row from 'react-bootstrap/Row'
import Table from 'react-bootstrap/Table'

export const USER_ID_SEARCH_AND_REPLACE_KEY = '{UserIdOfRep}'

export const CURRENT_STATUS_DATE_FIELD_NAME = 'Do Not Filter By Date (Current Status Metric)'
const FREE_RESPONSE_OPTION = 'Free Response (Type Something Else)'
export const NO_OPTION_SELECTED = 'None Selected'
const NO_DATE_FIELD_SELECTED = 'Select a Date or DateTime Field'
const NO_USER_FIELD_SELECTED = 'Select a User Id Field'
const DO_NOT_GROUP = 'do-not-group'
const COMPARISON_TYPE_MATCH = 'match'

export const NO_FILTER_ON_USER_OPTION = 'Do Not Filter By Owner'

function QueryBuilder({ sfObjects, setIsLoading, api, setConstructedQuery, constructedQuery }) {
  const [selectedObject, setSelectedObject] = useState('')
  const [userIdField, setUserIdField] = useState(NO_USER_FIELD_SELECTED)
  const [userIdCustomInput, setUserIdCustomInput] = useState('')
  const [dateField, setDateField] = useState(NO_DATE_FIELD_SELECTED)
  const [customDateInput, setCustomDateInput] = useState('')
  const [sumOptionField, setSumOptionField] = useState(NO_OPTION_SELECTED)
  const [sumCustomField, setSumCustomField] = useState('')
  const [dateType, setDateType] = useState(NO_OPTION_SELECTED)
  const [whereClauseInput, setWhereClauseInput] = useState('')
  const [isShowGroupingQuestions, setIsShowGroupingQuestions] = useState(false)
  const [groupByField, setGroupByField] = useState(NO_OPTION_SELECTED)
  const [minGroupSizeToCount, setMinGroupSizeToCount] = useState(1)
  const [sObjectFields, setSObjectFields] = useState([])
  const [showSObjectFieldsModal, setShowSObjectFieldsModal] = useState(false)
  const [isShowSumFreeResponse, setIsShowSumFreeResponse] = useState(false)
  const [isShowDateFreeResponse, setIsShowDateFreeResponse] = useState(false)
  const [isShowUserIdFreeResponse, setIsShowUserIdFreeResponse] = useState(false)
  const [isShowAdvancedFilterOptions, setIsShowAdvancedFilterOptions] = useState(false)
  const [selectedFilterField, setSelectedFilterField] = useState(NO_OPTION_SELECTED)
  const [comparisonValue, setComparisonValue] = useState('')
  const [filterComparisonType, setFilterComparisonType] = useState(COMPARISON_TYPE_MATCH)

  useEffect(() => {
    const getUserIdField = () => {
      if (optionToNotFilterIsSelected()) {
        return null
      }
      return userIdField === FREE_RESPONSE_OPTION ? userIdCustomInput : userIdField || null
    }
    const isValidUserIdField = () => {
      if (optionToNotFilterIsSelected()) {
        return true
      }
      const isUserFieldSelected = userIdField !== NO_USER_FIELD_SELECTED
      const isFreeResponseSelected = userIdField === FREE_RESPONSE_OPTION
      const isFreeResponseEntered = !!userIdCustomInput

      return (
        isUserFieldSelected &&
        (!isFreeResponseSelected || (isFreeResponseSelected && isFreeResponseEntered))
      )
    }
    const optionToNotFilterIsSelected = () => {
      return userIdField === NO_FILTER_ON_USER_OPTION
    }
    const getDateField = () => {
      const isCurrentStatusMetric = dateField === CURRENT_STATUS_DATE_FIELD_NAME
      if (isCurrentStatusMetric) {
        return null
      }
      const isFreeResponseSelected = dateField === FREE_RESPONSE_OPTION
      const shouldWrapInConvertTimeZone = dateType === 'datetime'
      const dateToUse = isFreeResponseSelected ? customDateInput : dateField
      return shouldWrapInConvertTimeZone ? `convertTimeZone(${dateToUse})` : dateToUse || null
    }
    const isValidDateField = () => {
      const isFreeResponseSelected = dateField === FREE_RESPONSE_OPTION
      const isCustomDateSpecified = !!customDateInput
      const isDateTypeSpecified = !!dateType && dateType !== NO_OPTION_SELECTED
      const isValidCustomResponse =
        !isFreeResponseSelected || (isCustomDateSpecified && isDateTypeSpecified)
      const isSomethingSelected = dateField !== NO_DATE_FIELD_SELECTED

      return isSomethingSelected && isValidCustomResponse
    }
    const getSummationField = () => {
      const isNotASumQuery = sumOptionField === NO_OPTION_SELECTED
      if (isNotASumQuery) {
        return null
      }
      return sumOptionField === FREE_RESPONSE_OPTION ? sumCustomField : sumOptionField || null
    }
    const isValidSummationField = () => {
      const isFreeResponseSelected = sumOptionField === FREE_RESPONSE_OPTION
      const isFreeResponseEntered = !!sumCustomField
      return !isFreeResponseSelected || (isFreeResponseSelected && isFreeResponseEntered)
    }
    const getWhereClause = () => {
      return whereClauseInput
        ? whereClauseInput.replace(/%/g, '%25').replace(/&/g, '%26').replace(/\n/g, ' ')
        : null
    }

    const isGroupingQuery = () => {
      return dateField === CURRENT_STATUS_DATE_FIELD_NAME && isShowGroupingQuestions
    }

    const getGroupingColumn = () => {
      if (!isGroupingQuery()) {
        return null
      }
      return groupByField === NO_OPTION_SELECTED ? null : groupByField
    }
    const getMinGroupSize = () => {
      if (!isGroupingQuery()) {
        return null
      }
      return parseInt(minGroupSizeToCount, 10) || null
    }
    const isGroupSectionValid = () => {
      if (!isGroupingQuery()) {
        return true
      }
      const groupingColumnHasBeenSelected = !!groupByField && groupByField !== NO_OPTION_SELECTED
      const groupSize = getMinGroupSize()
      return groupingColumnHasBeenSelected && groupSize && groupSize >= 1
    }

    const getFilterRule = () => {
      if (!isValidFilterRule() || thereIsNoFilterRule()) {
        return null
      }
      return {
        fieldName: selectedFilterField,
        regex: getRegex(),
      }
    }
    const getRegex = () => {
      if (filterComparisonType === COMPARISON_TYPE_MATCH) {
        return `^${comparisonValue}$`
      } else if (filterComparisonType === 'starts') {
        return `^${comparisonValue}`
      } else if (filterComparisonType === 'ends') {
        return `${comparisonValue}$`
      } else if (filterComparisonType === 'contains') {
        return comparisonValue
      } else if (filterComparisonType === 'regex') {
        return comparisonValue
      } else {
        return comparisonValue
      }
    }
    const isValidFilterRule = () => {
      if (thereIsNoFilterRule()) {
        return true
      }
      return !!comparisonValue
    }
    const thereIsNoFilterRule = () => {
      return selectedFilterField === NO_OPTION_SELECTED
    }

    const allInputsAreValid = () => {
      return (
        isValidDateField() &&
        isValidUserIdField() &&
        isValidSummationField() &&
        isGroupSectionValid() &&
        isValidFilterRule()
      )
    }

    if (allInputsAreValid()) {
      const query = {
        userIdField: getUserIdField(),
        dateField: getDateField(),
        summationField: getSummationField(),
        tableName: selectedObject,
        whereClauses: getWhereClause(),
        dateType: dateType,
        groupingColumn: getGroupingColumn(),
        minGroupSizeToCount: getMinGroupSize(),
        filterRule: getFilterRule(),
      }
      setConstructedQuery(query)
    } else {
      setConstructedQuery(null)
    }
  }, [
    comparisonValue,
    customDateInput,
    dateField,
    dateType,
    filterComparisonType,
    groupByField,
    isShowGroupingQuestions,
    minGroupSizeToCount,
    selectedFilterField,
    selectedObject,
    setConstructedQuery,
    sumCustomField,
    sumOptionField,
    userIdCustomInput,
    userIdField,
    whereClauseInput,
  ])

  const render = () => {
    return (
      <Fragment>
        <Card className='wrapper'>
          <Card.Body>
            <Card.Title>Query Builder</Card.Title>
            <Form>
              {renderObjectSelection()}
              {!!selectedObject && renderRequiredFieldSelection()}
              {requiredFieldsAreSelected() && renderOptionalFields()}
            </Form>
          </Card.Body>
        </Card>
        {renderSObjectFieldsModal()}
      </Fragment>
    )
  }

  const requiredFieldsAreSelected = () => {
    const objectIsSelected = !!selectedObject
    const userHasSelectedFreeResponseUserField = isShowUserIdFreeResponse
    const userHasSelectedFreeResponseDateField = isShowDateFreeResponse

    const userHasEnteredFreeResponseForUserField = !!userIdCustomInput
    const userHasEnteredFreeResponseForDateField = !!dateField && !!dateType

    const userIdFieldIsValid =
      !userHasSelectedFreeResponseUserField || userHasEnteredFreeResponseForUserField

    const dateFieldIsValid =
      !userHasSelectedFreeResponseDateField || userHasEnteredFreeResponseForDateField

    const userIdFieldIsSelected = userIdField !== NO_USER_FIELD_SELECTED && userIdFieldIsValid
    const dateFieldIsSelected = dateField !== NO_DATE_FIELD_SELECTED && dateFieldIsValid

    return objectIsSelected && userIdFieldIsSelected && dateFieldIsSelected
  }

  const renderObjectSelection = () => {
    return (
      <Fragment>
        <Form.Group controlId='object'>
          <Form.Label>
            <strong>Required: </strong>Select the Salesforce Object that Stores this metric
          </Form.Label>
          <Form.Control onChange={handleNewObject} as='select' data-testid='selectObject'>
            {sfObjects.map((sfObject, i) => {
              if (i === 0) {
                return (
                  <Fragment key='default-wrapper'>
                    <option defaultValue hidden key='default'>
                      Select an Object
                    </option>
                    <option key={sfObject}>{sfObject}</option>
                  </Fragment>
                )
              }
              return <option key={sfObject}>{sfObject}</option>
            })}
          </Form.Control>
        </Form.Group>
      </Fragment>
    )
  }

  const resetStateToDefault = () => {
    setSelectedObject('')
    setUserIdField(NO_USER_FIELD_SELECTED)
    setUserIdCustomInput('')
    setDateField(NO_DATE_FIELD_SELECTED)
    setCustomDateInput('')
    setSumOptionField(NO_OPTION_SELECTED)
    setSumCustomField('')
    setDateType(NO_OPTION_SELECTED)
    setWhereClauseInput('')
    setIsShowGroupingQuestions(false)
    setGroupByField(NO_OPTION_SELECTED)
    setMinGroupSizeToCount(1)
    setSObjectFields([])
    setShowSObjectFieldsModal(false)
    setIsShowSumFreeResponse(false)
    setIsShowDateFreeResponse(false)
    setIsShowUserIdFreeResponse(false)
    setIsShowAdvancedFilterOptions(false)
    setSelectedFilterField(NO_OPTION_SELECTED)
    setComparisonValue('')
    setFilterComparisonType(COMPARISON_TYPE_MATCH)
  }

  const handleNewObject = async (e) => {
    setIsLoading(true)
    setConstructedQuery(null)
    resetStateToDefault()

    const selectedObject = e.target.value
    const fields = await api.describeObject(selectedObject)
    setSObjectFields(fields)
    setSelectedObject(selectedObject)
    setIsLoading(false)
  }

  const renderRequiredFieldSelection = () => {
    return (
      <Fragment>
        {renderSelectUserIdField()}
        {renderSelectDateField()}
      </Fragment>
    )
  }

  const renderSelectUserIdField = () => {
    return (
      <Fragment>
        <Form.Group controlId='owner'>
          <Form.Label>
            <strong>Required: </strong>Which field indicates who should get credit?
          </Form.Label>
          <Form.Control
            value={userIdField}
            onChange={handleUserIdChange}
            required
            as='select'
            data-testid='selectUserField'
          >
            {getUserIdFields().map((userIdField, i) => {
              if (i === 0) {
                return (
                  <Fragment key='default-wrapper'>
                    <option defaultValue key='default'>
                      {NO_USER_FIELD_SELECTED}
                    </option>
                    <option key={userIdField}>{userIdField}</option>
                  </Fragment>
                )
              }
              return <option key={userIdField}>{userIdField}</option>
            })}
            <option key='free'>{FREE_RESPONSE_OPTION}</option>
            <option key='no-field'>{NO_FILTER_ON_USER_OPTION}</option>
          </Form.Control>
        </Form.Group>
        {isShowUserIdFreeResponse && renderUserIdFreeResponse()}
      </Fragment>
    )
  }

  const handleUserIdChange = (e) => {
    setUserIdField(e.target.value)
    setIsShowUserIdFreeResponse(e.target.value === FREE_RESPONSE_OPTION)
  }

  const getUserIdFields = () => {
    return sObjectFields
      .filter(
        (field) => field.referenceTo && field.referenceTo.some((reference) => reference === 'User')
      )
      .map((field) => field.name)
  }

  const renderUserIdFreeResponse = () => {
    return (
      <Form.Group controlId='owner2' className='indent'>
        <Form.Label>
          <strong>Required: </strong>Type the name of User field that should be used to identify the
          rep
        </Form.Label>
        <Form.Control
          onChange={handleCustomUserIdFieldEntry}
          type='text'
          placeholder='Custom_Object__r.User_Id_Field__c'
          required
          value={userIdCustomInput}
        />
      </Form.Group>
    )
  }

  const handleCustomUserIdFieldEntry = (e) => {
    setUserIdCustomInput(e.target.value)
  }

  const renderSelectDateField = () => {
    return (
      <Fragment>
        <Form.Group controlId='date'>
          <Form.Label>
            <strong>Required: </strong>Which field indicates when the credit should be applied?
          </Form.Label>
          <Form.Control
            value={dateField}
            onChange={handleDateFieldChange}
            required
            as='select'
            data-testid='selectDateField'
          >
            {getDateFields().map((dateFields, i) => {
              if (i === 0) {
                return (
                  <Fragment key='default-wrapper'>
                    <option defaultValue key='default'>
                      {NO_DATE_FIELD_SELECTED}
                    </option>
                    <option key={dateFields.name}>{dateFields.name}</option>
                  </Fragment>
                )
              }
              return <option key={dateFields.name}>{dateFields.name}</option>
            })}
            <option defaultValue key='free'>
              {FREE_RESPONSE_OPTION}
            </option>
            <option key='no-date'>{CURRENT_STATUS_DATE_FIELD_NAME}</option>
          </Form.Control>
        </Form.Group>
        {isShowDateFreeResponse && renderDateFreeResponse()}
      </Fragment>
    )
  }

  const handleDateFieldChange = (e) => {
    setIsShowDateFreeResponse(e.target.value === FREE_RESPONSE_OPTION)
    setDateField(e.target.value)
    const dateField = getDateFields().find((dateField) => dateField.name === e.target.value)
    if (!dateField) {
      setDateType(null)
    } else {
      setDateType(dateField.type)
    }
  }

  const getDateFields = () => {
    return sObjectFields
      .filter((field) => field.type === 'date' || field.type === 'datetime')
      .map((field) => {
        return { name: field.name, type: field.type }
      })
  }

  const renderDateFreeResponse = () => {
    return (
      <Form.Group controlId='custom-date' className='indent'>
        <Form.Label>
          <strong>Required: </strong>Type the name of Date or DateTime field that shows for what day
          the record should be counted
        </Form.Label>
        <Form.Control
          onChange={handleCustomDateFieldEntry}
          type='text'
          placeholder='Opportunity.CloseDate'
          required
          value={customDateInput}
        />
        <Form.Label>
          <strong>Required: </strong>Please specify the type
        </Form.Label>
        <Form.Control onChange={handleDateTypeUpdate} as='select'>
          <Fragment key='default-wrapper'>
            <option defaultValue key='default'>
              {NO_OPTION_SELECTED}
            </option>
            <option key='datetime' value='datetime'>
              Date Time
            </option>
            <option key='date' value='date'>
              Date
            </option>
          </Fragment>
        </Form.Control>
      </Form.Group>
    )
  }

  const handleCustomDateFieldEntry = (e) => {
    setCustomDateInput(e.target.value)
  }

  const handleDateTypeUpdate = (e) => {
    if (e.target.value === NO_OPTION_SELECTED) {
      setDateType(null)
    } else {
      setDateType(e.target.value)
    }
  }

  const renderOptionalFields = () => {
    return (
      <Fragment>
        {renderSelectSumField()}
        {!(isShowSumFreeResponse && !sumCustomField) && renderRestOfForm()}
      </Fragment>
    )
  }

  const renderSelectSumField = () => {
    return (
      <Fragment>
        <Form.Group controlId='sum'>
          <Form.Label>
            <strong>Optional: </strong>Which field should we sum up on the returned records? (Used
            for non-count metrics)
          </Form.Label>
          <Form.Control value={sumOptionField} onChange={handleSumOptionSelection} as='select'>
            <Fragment key='default-wrapper'>
              <option defaultValue key='default'>
                {NO_OPTION_SELECTED}
              </option>
              <option key='free'>{FREE_RESPONSE_OPTION}</option>
              {getSummableFields().map((sumFields, i) => {
                return <option key={sumFields.name}>{sumFields.name}</option>
              })}
            </Fragment>
          </Form.Control>
        </Form.Group>
        {isShowSumFreeResponse && renderSumFreeResponse()}
      </Fragment>
    )
  }

  const handleSumOptionSelection = (e) => {
    setIsShowSumFreeResponse(e.target.value === FREE_RESPONSE_OPTION)
    setSumOptionField(e.target.value)
  }

  const getSummableFields = () => {
    const nonSummableTypes = new Set([
      'datetime',
      'date',
      'picklist',
      'phone',
      'string',
      'textarea',
      'reference',
      'url',
      'id',
      'email',
      'combobox',
    ])
    return sObjectFields
      .filter((field) => field.aggregatable === true && !nonSummableTypes.has(field.type))
      .map((field) => {
        return { name: field.name, type: field.type }
      })
  }

  const renderSumFreeResponse = () => {
    return (
      <Form.Group controlId='sum2' className='indent'>
        <Form.Label>
          <strong>Required: </strong>Type the name of field that should be summed
        </Form.Label>
        <Form.Control
          onChange={handleSumCustomEntry}
          type='text'
          placeholder='Custom_Object__r.Summable_Field__c'
          required
          value={sumCustomField}
        />
      </Form.Group>
    )
  }

  const handleSumCustomEntry = (e) => {
    setSumCustomField(e.target.value)
  }

  const renderRestOfForm = () => {
    const isCurrentStatus = dateField === CURRENT_STATUS_DATE_FIELD_NAME
    return (
      <Fragment>
        <Form.Group controlId='where'>
          <Row id='where-button'>
            <Col>
              <Form.Label>
                <strong>Optional: </strong>Under what conditions should a record be counted?
                ('WHERE' Clauses)
              </Form.Label>
            </Col>
            <Col>
              <Button onClick={() => setShowSObjectFieldsModal(true)}>Show Field Metadata</Button>
            </Col>
          </Row>
          <Form.Control
            onBlur={cleanWhereClauseForTest}
            onChange={handleWhereClauseChange}
            as='textarea'
            placeholder={`StageName = 'Closed-Won' AND OwnerId = ${USER_ID_SEARCH_AND_REPLACE_KEY}`}
            rows='3'
            data-testid='whereClauseInput'
            value={whereClauseInput || ''}
          />
        </Form.Group>
        {!!getUnfilterableFields().length && dateField === CURRENT_STATUS_DATE_FIELD_NAME && (
          <Row>
            <Col>
              <div
                id='show-advanced-filter-btn'
                onClick={() => setIsShowAdvancedFilterOptions(!isShowAdvancedFilterOptions)}
                aria-controls='example-collapse-text'
                aria-expanded={isShowAdvancedFilterOptions}
                data-testid='showAdvancedFilterOption'
              >
                {isShowAdvancedFilterOptions ? 'Hide' : 'Show'} Advanced Filter Options
              </div>
              {isShowAdvancedFilterOptions && renderAdvancedFilterOptions()}
            </Col>
          </Row>
        )}
        {isCurrentStatus && renderGroupBy()}
      </Fragment>
    )
  }

  const handleWhereClauseChange = (e) => {
    setWhereClauseInput(e.target.value)
  }

  const cleanWhereClauseForTest = () => {
    if (whereClauseInput && whereClauseInput.value !== '') {
      setWhereClauseInput(whereClauseInput.replace(/%25/g, '%').replace(/%26/g, '&'))
    }
  }

  const renderAdvancedFilterOptions = () => {
    return (
      <Fragment>
        <Form.Group>
          <Form.Label>Select a field to filter by</Form.Label>
          <Form.Control
            value={selectedFilterField}
            onChange={handleFilterFieldChange}
            as='select'
            data-testid='selectFilterField'
          >
            <option defaultValue key='default'>
              {NO_OPTION_SELECTED}
            </option>
            {getUnfilterableFields().map((field) => {
              return (
                <option key={field.name} value={field.name}>
                  {field.name}
                </option>
              )
            })}
          </Form.Control>
        </Form.Group>
        <Form.Group>
          <Form.Label>Select a comparison option</Form.Label>
          <Form.Control
            as='select'
            onChange={handleFilterComparisonTypeChange}
            value={filterComparisonType}
            data-testid='comparisonType'
          >
            <option key='exact' value={COMPARISON_TYPE_MATCH}>
              Matches Exactly
            </option>
            <option key='starts' value='starts'>
              Starts With
            </option>
            <option key='ends' value='ends'>
              Ends With
            </option>
            <option key='contains' value='contains'>
              Contains
            </option>
            <option key='regex' value='regex'>
              Regex (visit https://regex101.com)
            </option>
          </Form.Control>
        </Form.Group>
        <Form.Group>
          <Form.Label>Value</Form.Label>
          <Form.Control
            onChange={handleComparisonValueChange}
            type='text'
            placeholder={'Discovery'}
            required
            value={comparisonValue}
            data-testid='comparisonValue'
          />
        </Form.Group>
      </Fragment>
    )
  }

  const handleFilterFieldChange = (e) => {
    setSelectedFilterField(e.target.value)
  }

  const getUnfilterableFields = () => {
    return sObjectFields.filter((field) => !field.filterable)
  }

  const handleFilterComparisonTypeChange = (e) => {
    setFilterComparisonType(e.target.value)
  }

  const handleComparisonValueChange = (e) => {
    setComparisonValue(e.target.value)
  }

  const renderGroupBy = () => {
    return (
      <Fragment>
        <Form.Group>
          <Form.Label>
            <strong>Optional: </strong>Select how to count the records
          </Form.Label>
          <Form.Control
            value={isShowGroupingQuestions ? 'group' : DO_NOT_GROUP}
            onChange={handleGroupSelectionChange}
            as='select'
          >
            <Fragment key='default-wrapper'>
              <option key={DO_NOT_GROUP} value={DO_NOT_GROUP}>
                Count records individually
              </option>
              <option key='group' value='group'>
                Count groups of records based on group size
              </option>
            </Fragment>
          </Form.Control>
        </Form.Group>
        {isShowGroupingQuestions && renderGroupingQuestions()}
      </Fragment>
    )
  }

  const handleGroupSelectionChange = (e) => {
    setIsShowGroupingQuestions(e.target.value !== DO_NOT_GROUP)
  }

  const renderGroupingQuestions = () => {
    return (
      <Fragment>
        <Form.Group>
          <Form.Label>
            <strong>Required: </strong>Select which field to group the records on
          </Form.Label>
          <Form.Control value={groupByField} onChange={handleGroupByFieldChange} as='select'>
            <Fragment key='default-wrapper'>
              <option defaultValue key='default'>
                {NO_OPTION_SELECTED}
              </option>
              {getGroupableFields().map((field) => {
                return (
                  <option key={field.name} value={field.name}>
                    {field.name}
                  </option>
                )
              })}
            </Fragment>
          </Form.Control>
          <Form.Label>
            <strong>Required: </strong>How many records does a group need to have for the group to
            be counted?
          </Form.Label>
          <Form.Control
            type='number'
            onChange={handleMinRecordCountChange}
            min='1'
            step='1'
            value={minGroupSizeToCount}
          />
        </Form.Group>
      </Fragment>
    )
  }

  const handleGroupByFieldChange = (e) => {
    setGroupByField(e.target.value)
  }

  const getGroupableFields = () => {
    return sObjectFields.filter((field) => field.groupable && field.name !== 'Id')
  }

  const handleMinRecordCountChange = (e) => {
    setMinGroupSizeToCount(e.target.value)
  }

  const renderSObjectFieldsModal = () => {
    return (
      <Modal show={showSObjectFieldsModal} onHide={handleCloseSObjectFieldsModal} size='xl'>
        <Modal.Header closeButton>
          <Modal.Title>Field Metadata for {selectedObject}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table bordered hover size='sm'>
            <thead>
              <tr>
                <th>Field Name</th>
                <th>Field API Name</th>
                <th>Field Type</th>
                <th>Notes</th>
              </tr>
            </thead>
            <tbody>
              {sObjectFields.map((field) => {
                return (
                  <tr key={JSON.stringify(field.name)}>
                    <td>{field.label}</td>
                    <td>{field.name}</td>
                    <td>{field.type}</td>
                    <td>
                      {field.type === 'picklist' || field.type === 'combobox'
                        ? field.picklistValues
                            .map((picklistValue) => picklistValue.value)
                            .join(', ')
                        : field.type === 'reference'
                        ? field.referenceTo.join(', ')
                        : ''}
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </Table>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='primary' onClick={handleCloseSObjectFieldsModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    )
  }

  const handleCloseSObjectFieldsModal = () => {
    setShowSObjectFieldsModal(false)
  }

  return render()
}

export default QueryBuilder
