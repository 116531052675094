import TestResult from './TestResult'

const baseUrl = 'https://sync.databased.com'

class SyncMicroServiceAPI {
  constructor(setSOQLQuery, constructedQuery, selectedUsersCrmIds, startDate, endDate, authToken) {
    this.setSOQLQuery = setSOQLQuery
    this.constructedQuery = constructedQuery
    this.selectedUsersCrmIds = selectedUsersCrmIds
    this.startDate = !!constructedQuery.dateField ? startDate : null
    this.endDate = !!constructedQuery.dateField ? endDate : null
    this.authToken = authToken
  }

  async getResults() {
    const route = `${baseUrl}/query/evaluationRequest`
    const response = await fetch(route, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${this.authToken}`,
        'Content-Type': 'application/json',
      },
      body: this.getBody(),
    })
    const parsed = await response.json()

    const isAServerError = parsed && parsed.message && parsed.code
    const isASalesforceError =
      parsed && parsed.performances && parsed.performances[0] && parsed.performances[0].message

    if (isAServerError) {
      return [{ errorCode: parsed.code, message: parsed.message }]
    } else if (isASalesforceError) {
      return parsed.performances
    } else {
      if (parsed.soqlQuery) {
        this.setSOQLQuery(parsed.soqlQuery)
      }
      return parsed.performances.map((p) => new TestResult(p.userSalesforceId, p.performance))
    }
  }

  getBody() {
    return JSON.stringify({
      queryObject: this.constructedQuery,
      startDate: this.startDate,
      endDate: this.endDate,
      userSalesforceIds: this.selectedUsersCrmIds,
    })
  }
}

export default SyncMicroServiceAPI
