import React, { useState } from 'react'
import Login from './Utils/Login'
import Notifier from './Utils/Notifier'
import QueryExplorer from './QueryExplorer/QueryExplorer'
import Spinner from 'react-bootstrap/Spinner'
import './App.css'

const baseUrl = 'https://api.databased.com'

function App() {
  const [authToken, setAuthToken] = useState('')
  const [isLoggedIn, setIsLoggedIn] = useState(false)
  const [isLoading, setIsLoadingState] = useState(false)
  const [isNotifying, setIsNotifying] = useState(false)
  const [message, setMessage] = useState('')

  const notifyUser = (message) => {
    window.scrollTo(0, 0)
    setMessage(message)
    setIsNotifying(true)
    setTimeout(() => {
      setIsNotifying(false)
    }, 5000)
  }

  const setIsLoading = (isLoading) => {
    if (isLoading) {
      window.scrollTo(0, 0)
    }
    setIsLoadingState(isLoading)
  }

  const renderLogin = () => {
    return (
      <Login
        baseUrl={baseUrl}
        setAuthToken={setAuthToken}
        setIsLoading={setIsLoading}
        setIsLoggedIn={setIsLoggedIn}
        notifyUser={notifyUser}
      />
    )
  }

  return (
    <div className='App'>
      {isNotifying && <Notifier message={message} />}
      {isLoading && <Spinner animation='border' />}
      {!isLoggedIn && renderLogin()}
      {isLoggedIn && (
        <QueryExplorer
          authToken={authToken}
          baseUrl={baseUrl}
          setIsLoading={setIsLoading}
          notifyUser={notifyUser}
        ></QueryExplorer>
      )}
    </div>
  )
}

export default App
