import React, { Fragment } from 'react'
import Accordion from 'react-bootstrap/Accordion'
import Card from 'react-bootstrap/Card'
import Form from 'react-bootstrap/Form'

const getUniqueMetricProfileIds = (users) => {
  return Array.from(new Set(users.map((user) => user.metricProfileId)))
}

export default function UserList({ users, setSelectedUserCrmIds }) {
  if (!users) {
    return null
  }

  const renderRowWithCheckBox = (user) => {
    return (
      <Fragment>
        <Form.Group controlId={`checkbox-for-${user.id}`}>
          <Form.Check
            onChange={handleUserSelected}
            data-user-id={user.id}
            name='user-checkbox'
            type='checkbox'
            label={`${user.fullName}: ${user.jobTitle}`}
          />
        </Form.Group>
      </Fragment>
    )
  }

  const handleUserSelected = () => {
    updateSelectedUsers()
  }

  const updateSelectedUsers = () => {
    const selectedUserCrmIds = []
    users.forEach((user) => {
      if (document.getElementById(`checkbox-for-${user.id}`).checked) {
        selectedUserCrmIds.push(user.crmId)
      }
    })
    setSelectedUserCrmIds(selectedUserCrmIds)
  }

  const handleToggleAll = (event) => {
    const { target } = event
    const metricProfileId = target.dataset.metricProfileId
    const userIds = users
      .filter((user) => user.metricProfileId === parseInt(metricProfileId, 10))
      .map((user) => user.id)
    userIds.forEach((userId) => {
      document.getElementById(`checkbox-for-${userId}`).checked = target.checked
    })
    updateSelectedUsers()
  }

  const uniqueMetricProfileIds = getUniqueMetricProfileIds(users)

  return (
    <Fragment>
      <p>Choose the users to include in the Salesforce data results</p>
      <Accordion defaultActiveKey={0}>
        {uniqueMetricProfileIds
          .sort((a, b) => b - a)
          .map((metricProfileId, idx) => {
            const jobTitles = users
              .filter((user) => user.metricProfileId === metricProfileId)
              .map((user) => user.jobTitle)
            const uniqueJobTitles = [...new Set(jobTitles)]
            const uniqueJobTitlesStr = uniqueJobTitles.join(',')
            const cardHeader =
              metricProfileId === -1
                ? `Untracked Users: (${uniqueJobTitlesStr})`
                : `Users with DataBased metric profile id: ${metricProfileId} (${uniqueJobTitlesStr})`
            const index = idx + 1

            return (
              <Card key={metricProfileId}>
                <Accordion.Toggle as={Card.Header} eventKey={index}>
                  {cardHeader}
                </Accordion.Toggle>
                <Accordion.Collapse eventKey={index}>
                  <Card.Body>
                    <Form.Group controlId={`select-all-${metricProfileId}`}>
                      <Form.Check
                        className='text-primary'
                        type='checkbox'
                        onChange={handleToggleAll}
                        data-metric-profile-id={metricProfileId}
                        label={`Select/Deselect All`}
                      />
                    </Form.Group>
                    {users
                      .filter((user) => user.metricProfileId === metricProfileId)
                      .map((user) => {
                        return <div key={user.id}>{renderRowWithCheckBox(user)}</div>
                      })}
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            )
          })}
      </Accordion>
    </Fragment>
  )
}
