import React, { useEffect, useState } from 'react'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import QueryBuilder from './QueryBuilder'
import QueryTester from './QueryTester/QueryTester'
import Row from 'react-bootstrap/Row'
import SalesforceAPI from '../Utils/SalesforceAPI'
import TestResultsModal from './TestResultsReport/TestResultsModal'

function QueryExplorer({ authToken, baseUrl, setIsLoading, notifyUser }) {
  const [users, setUsers] = useState([])
  const [sfObjects, setSfObjects] = useState([])
  const [constructedQuery, setConstructedQuery] = useState(null)
  const [testResults, setTestResults] = useState(null)
  const [showTestResults, setShowTestResults] = useState(false)
  const [soqlQuery, setSOQLQuery] = useState('')
  const [selectedUsersCrmIds, setSelectedUserCrmIds] = useState([])
  const [isGettingTestResults, setIsGettingTestResults] = useState(false)

  useEffect(() => {
    if (users.length === 0) {
      getUsers(authToken, baseUrl).then((userList) => {
        setUsers(userList.filter((user) => !user.isDeactivated))
      })
    }
    getSfObjects(authToken, baseUrl).then(async (objects) => {
      setSfObjects(objects)
    })
    setIsLoading(users.length === 0 || sfObjects.length === 0 || isGettingTestResults)
  }, [authToken, baseUrl, isGettingTestResults, setIsLoading, sfObjects.length, users.length])

  const render = () => {
    if (users.length === 0 || sfObjects.length === 0) {
      return null
    }

    return (
      <div>
        <div>
          <Container>
            <Row>
              <Col>
                <QueryBuilder
                  sfObjects={sfObjects}
                  setIsLoading={setIsLoading}
                  api={new SalesforceAPI(baseUrl, authToken)}
                  setConstructedQuery={setConstructedQuery}
                  constructedQuery={constructedQuery}
                />
              </Col>
              <Col>
                <QueryTester
                  isDisabled={!!constructedQuery}
                  constructedQuery={constructedQuery}
                  notifyUser={notifyUser}
                  users={users}
                  setIsLoading={setIsGettingTestResults}
                  setTestResults={setTestResults}
                  setShowTestResults={setShowTestResults}
                  setSOQLQuery={setSOQLQuery}
                  setSelectedUserCrmIds={setSelectedUserCrmIds}
                  selectedUsersCrmIds={selectedUsersCrmIds}
                  authToken={authToken}
                ></QueryTester>
              </Col>
            </Row>
          </Container>
        </div>
        {!!testResults && !!constructedQuery && (
          <TestResultsModal
            users={users}
            testResults={testResults}
            showTestResults={showTestResults}
            setShowTestResults={setShowTestResults}
            soqlQuery={soqlQuery}
            constructedQuery={constructedQuery}
            selectedUsersCrmIds={selectedUsersCrmIds}
          ></TestResultsModal>
        )}
      </div>
    )
  }

  const getUsers = async (authToken, baseUrl) => {
    const route = `${baseUrl}/api/users`
    const response = await fetch(route, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    })
    const userArray = await response.json()
    if (Array.isArray(userArray)) {
      const users = userArray.sort((a, b) => {
        if (a.fullName > b.fullName) {
          return 1
        } else if (b.fullName > a.fullName) {
          return -1
        }
        return 0
      })
      users.forEach((user) => {
        const currentMetricProfile = user.metricProfiles.find(
          (metricProfile) => metricProfile.endDate === null
        )
        user.metricProfileId = currentMetricProfile ? currentMetricProfile.id : -1
      })
      return users
    }
    return []
  }

  const getSfObjects = async (authToken, baseUrl) => {
    const response = await fetch(`${baseUrl}/crm/salesforce/data/objects`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    })
    const data = await response.json()
    if (data && data.sobjects) {
      const sobjects = data.sobjects
        .filter((sObject) => {
          return sObject.queryable === true && sObject.retrieveable === true
        })
        .map((sObject) => sObject.name)
        .sort()
      return sobjects
    }
    return []
  }

  return render()
}

export default QueryExplorer
