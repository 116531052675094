import React, { Fragment } from 'react'
import Table from 'react-bootstrap/Table'

const getPercentile = (data, percentile) => {
  data.sort(numSort)
  var index = (percentile / 100) * data.length
  var result
  if (Math.floor(index) === index) {
    result = (data[index - 1] + data[index]) / 2
  } else {
    result = data[Math.floor(index)]
  }
  return result
}

const numSort = (a, b) => {
  return a - b
}

function QuantitativeAnalysis({ values }) {
  if (values.length <= 1) {
    return null
  }

  return (
    <Fragment>
      <Table bordered hover size='sm'>
        <thead>
          <tr>
            <th id='analysis-header'>Statistical Analysis Metric</th>
            <th>Value</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Minimum Value</td>
            <td>{Math.min.apply(Math, values)}</td>
          </tr>
          <tr>
            <td>20th Percentile (80% Attainment)</td>
            <td>{getPercentile(values, 20)}</td>
          </tr>
          <tr>
            <td>25th Percentile</td>
            <td>{getPercentile(values, 25)}</td>
          </tr>
          <tr>
            <td>40th Percentile (60% Attainment)</td>
            <td>{getPercentile(values, 40)}</td>
          </tr>
          <tr>
            <td>Median</td>
            <td>{getPercentile(values, 50)}</td>
          </tr>
          <tr>
            <td>60th Percentile (40% Attainment)</td>
            <td>{getPercentile(values, 60)}</td>
          </tr>
          <tr>
            <td>75th Percentile</td>
            <td>{getPercentile(values, 75)}</td>
          </tr>
          <tr>
            <td>Maximum Value</td>
            <td>{Math.max.apply(Math, values)}</td>
          </tr>
        </tbody>
      </Table>
    </Fragment>
  )
}

export default QuantitativeAnalysis
