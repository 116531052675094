class SalesforceAPI {
  constructor(baseUrl, authToken) {
    this.baseUrl = baseUrl
    this.authToken = authToken
  }

  async describeObject(sObjectName) {
    const response = await fetch(
      `${this.baseUrl}/crm/salesforce/data/objects/${sObjectName}/fields`,
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${this.authToken}`,
        },
      }
    )
    const fields = await response.json()
    fields.sort((a, b) => {
      if (a.name > b.name) return 1
      if (b.name > a.name) return -1
      return 0
    })
    return fields
  }
}

export default SalesforceAPI
